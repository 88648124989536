import { useCallback } from 'react'

import { CrossLargeIcon } from '@/components/icons/CrossLargeIcon'
import { useDeleteFile } from '@/features/task/hooks/useDeleteFile'
import { UploadState } from '@/lib/fileUpload'

const ChatMessageInputFilePreview = ({
  file,
  filesState,
  onDelete,
}: {
  file: File
  filesState: Record<string, UploadState>
  onDelete: (fileName: string) => void
}) => {
  const handleDelete = useCallback(() => {
    onDelete(file.name)
  }, [onDelete, file.name])

  return (
    <div key={file.name} className="relative">
      <img
        src={URL.createObjectURL(file)}
        alt={file.name}
        className="h-24 w-24 rounded-md object-cover"
      />
      <button
        className="absolute right-1 top-1 flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-black hover:bg-film-strongest"
        data-testid={`delete-file-${file.name}`}
        onClick={handleDelete}
      >
        <CrossLargeIcon className="h-4 w-4 p-0 text-white" />
      </button>
      {!filesState[file.name]?.done && (
        <div className="absolute bottom-0 left-0 h-2 w-full overflow-hidden bg-gray-100">
          <div
            data-testid={`upload-progress-${file.name}`}
            role="progressbar"
            className="absolute h-full bg-blue-500 transition-all duration-300"
            style={{
              width: `${filesState[file.name]?.progress ?? 0}%`,
            }}
          />
        </div>
      )}
    </div>
  )
}

ChatMessageInputFilePreview.displayName = 'ChatMessageInputFilePreview'

export const ChatMessageInputFileList = ({
  fileList,
  filesState,
  onKeyPress,
  setFileList,
}: {
  fileList: File[]
  filesState: Record<string, UploadState>
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void
  setFileList: (fileList: File[]) => void
}) => {
  const { mutate: deleteFile } = useDeleteFile()
  const handleDelete = useCallback(
    (fileName: string) => {
      const fileId = filesState[fileName]?.data?.id

      setFileList(fileList.filter((file) => file.name !== fileName))
      if (fileId) {
        deleteFile(fileId)
      }
    },
    [setFileList, fileList, filesState, deleteFile]
  )

  const reference = useCallback((node: HTMLDivElement) => {
    if (node) {
      node.focus()
    }
  }, [])

  return (
    <div
      className="mb-3 flex flex-row flex-wrap items-center gap-2 border-b border-film-strong px-3 pb-3 focus:outline-none"
      onKeyDown={onKeyPress}
      ref={reference}
      tabIndex={-1}
      data-testid="file-list"
    >
      {fileList.map((file) => (
        <ChatMessageInputFilePreview
          file={file}
          filesState={filesState}
          onDelete={handleDelete}
          key={file.name}
        />
      ))}
    </div>
  )
}

ChatMessageInputFileList.displayName = 'ChatMessageInputFileList'
